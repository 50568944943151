.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(121 121 121 / 92%);
  justify-content: center;
  align-items: center;
}

.popup_modal {
  position: relative;
  width: 400px;
  height: 500px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(148, 148, 148);
  box-shadow: 9px 10px 20px -5px rgb(0 17 74 / 80%);
  /* background-image: linear-gradient(rgb(215 246 255 / 65%), rgb(237 234 255)), url('./background.jpg'); */
  background-color: #f7fbff;
  border-radius: 5px;
  z-index: 0;
}

.popup_modal input:focus {
  outline: none;
}

.show_modal {
  display: flex;
}

.form_group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

.input_group {
  width: 100%;
  margin: 5px 0;
}

.input_group label {
  font-size: 15px;
  margin: 10px 0 5px 20px;
}

.input_group input[type='checkbox'] {
  margin: 0 10px 0 15px;
}

.input_checkbox {
  margin-top: 15px;
}

.modal_input {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  color: black;
  width: 100%;
  height: 30px;
  margin-top: 5px;
  background-color: rgba(230, 230, 230, 0.15);
  text-indent: 15px;
}

.modal_input::placeholder {
  color: rgb(121, 121, 121);
}

.modal_button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 100%;
  height: 30px;
  margin-top: 35px;
  margin-bottom: 20px;
  color: white;
  background-color: rgb(20 60 132);
  transition: all 0.5s;
}

.modal_button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.modal_button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.modal_button:hover:enabled {
  cursor: pointer;
}

.modal_button:hover span {
  padding-right: 25px;
}

.modal_button:hover span:after {
  opacity: 1;
  right: 0;
}

.modal_button:active {
  background-color: rgb(11, 58, 146);  
}

.modal_button:focus {
  outline: none;
}

.modal_button:disabled,
.modal_button[disabled] {
  background-color: rgb(79, 99, 134);  
  color: #e4e4e4;
}

.close_button {
  position: absolute;
  right: -20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
}

.close_button:hover {
  color: white;
}

.header {
  position: relative;
  width: 100%;
  height: 16%;
}

.error {
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body {
  width: 100%;
  flex: 1;
  color: rgb(56, 56, 56);
}

.footer {
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.tab_bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 21px;
  display: flex;
  justify-content: space-around;
}

.tab_bar span {
  transition: all 0.3s;
}

.mode_tab {
  padding: 5px 0;
  margin: 0 20px 0 15px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.mode_tab:hover {
  color: rgb(20 60 132);
}

.tab_selected {
  border-bottom-color: rgb(20 60 132);
  color: rgb(20 60 132);
}

@media only screen and (max-width: 600px) {
  .popup_modal {
    width: 100%;
    height: 100%;
  }
}

.error_message {
  background-color: rgba(255, 0, 0, 0.76);
  text-align: center;
  color: white;
  width: 100%;
  margin: 0;
}