.logo {
  /* position: absolute; */
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  background: #c0efff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.logo h1 {
  color: #d54d7b;
  font-family: "Great Vibes", cursive;
  font-size: 65px;
  width: 150px;
  line-height: 160px;
  text-indent: 20px;
  font-weight: normal;
  text-align: center;
  text-shadow: 5px 5px 4px #442e2e;
}

.logo a {
  width: 150px;
}

.logo img {
  height: 100%;
}

.logo-space {
  width: 150px;
}

.container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 50px;
  min-height: calc(100vh - 200px);
}

.upload-container {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-icon {
  width: 100px;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 500px;
  height: 200px;
  border: 2px dashed #eeeeee;
  transition: border .24s ease-in-out;
  background-color: #fafafa;
  outline: none;
  cursor: pointer;
}

.dropzone span {
  color: #4aa1f3;
}

.footer {
  margin: 0 auto;
  width: 500px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.footer span {
  font-size: 20px;
  color: #10486b;
  margin-bottom: 50px;
}

.labels {
  display: none;
  text-align: center;
}

.label {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.custom-progress-bar {
  position: relative;
  height: 25px;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #333;
}

.custom-filter {
  background: rgb(22, 89, 189);
  height: 100%;
  border-radius: inherit;
  transition: width .1s ease-in;
}

.form-page__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
  width: 100%;
}

.form-page__form-wrapper {
  max-width: 325px;
  width: 100%;
  border: 1px solid #EDEDED;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.form-page__form-heading {
  text-align: center;
  font-size: 1.5em;
  user-select: none;
}

.form-page__form-header {
  padding: 1em;
}

.form__field-wrapper {
  width: 100%;
  position: relative;
  padding-top: 1.75em;
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  background-color: #fff;
}

.form__field-wrapper + .form__field-wrapper {
  border-top: none;
}

.form__field-input:focus ~ .form__field-label {
  color: #666;
  background-color: #EDEDED;
}

.form__field-input:focus {
  background-color: #EDEDED;
  color: #333;
}

.form__field-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  padding-top: 20px;
  padding-bottom: 0;
  margin: 0;
  z-index: 1;
  font-size: .8em;
  color: #999;
  font-weight: 400;
  user-select: none;
  cursor: text;
}

.form__field-input {
  position: relative;
  padding: 1.625em 16px;
  width: 100%;
  color: #666;
  border: none;
  outline: 0;
  letter-spacing: 0.05em;
}

.form__submit-btn-wrapper {
  padding: 2em 1em;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.form__submit-btn {
  border: none;
  background-color: #fff;
  border: 1px solid #6CC0E5;
  padding: 0.5em 1em;
  border-radius: 3px;
  background-color: #6CC0E5;
  color: white;
  display: block;
  margin: 0 auto;
  position: relative;
}

.js-form__err-animation {
  animation: shake 150ms ease-in-out;
}

.form__error-wrapper {
  /* display: none; */
  justify-content: center;
  max-width: calc(100% - 2em);
  margin: 0 auto;
  margin-bottom: 1em;
}

.form__error {
  /* display: none; */
  background-color: #FB4F4F;
  color: white;
  margin: 0;
  padding: 0.5em 1em;
  font-size: 0.8em;
  font-family: 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  user-select: none;
}

.js-form__err .form__error-wrapper {
  display: flex;
}

.backLeft {
  margin: 0 50px 0 -50px;
  color: #007bff;
}

.backLeft:hover {
  color: #004b9b;
  cursor: pointer;
}

.not_signup_yet {
  display: flex;
  justify-content: center;
  align-items: center;
}

.not_found_page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.admin-container {
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}